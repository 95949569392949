"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";
import { Button } from "@openchatai/hoose/ui";
export const metadata = {
  title: "404",
  description: "Page not found",
  keywords: "404, page not found, not found",
  robots: "noindex, nofollow"
};
export default function NotFoundPage() {
  const router = useRouter();
  return <div className="flex-center h-full w-full" data-sentry-component="NotFoundPage" data-sentry-source-file="not-found.tsx">
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="relative mx-auto aspect-square w-56">
          <Image unoptimized src="/404.gif" alt="404" fill data-sentry-element="Image" data-sentry-source-file="not-found.tsx" />
        </div>
        <h1 className="text-5xl font-bold">404</h1>
        <Button onClick={router.back} data-sentry-element="Button" data-sentry-source-file="not-found.tsx">Go back</Button>
      </div>
    </div>;
}